import { FC, useEffect } from "react";
import { useApp } from "../Context/AppContext";
import { Navigate, useNavigate } from "react-router-dom";

export const Home: FC = () => {
    const { session } = useApp();
    const navigate = useNavigate();

    useEffect(() => {
        if (!!session) {
            navigate("app");
        }
    }, []);

    if (!!session) return null;

    return (
        <>
            {!!session && <Navigate to="app" />}

            <h1>Home</h1>

        </>
    );
};