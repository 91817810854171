// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,500;0,600;0,700;1,200;1,400;1,500;1,600;1,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zH9ROxgcKWTuti5eIt5P{display:flex;justify-content:space-between;align-items:center}.JXH_wQMqxkfFAAEu3DIg{border-left:8px solid #63d37d}.eHzGgICKUJW9cxa5M9zw{color:#63d37d}.z5tp54ZbQXC7IG6OfuPS{display:flex;gap:6px;align-items:center}`, "",{"version":3,"sources":["webpack://./Client/Pages/Customer/Customer.module.scss","webpack://./../Aphrodite.React/Styles/defaults.scss"],"names":[],"mappings":"AAEA,sBACI,YAAA,CACA,6BAAA,CACA,kBAAA,CAGJ,sBACI,6BAAA,CAGJ,sBACI,aCoBM,CDjBV,sBACE,YAAA,CACA,OAAA,CACA,kBAAA","sourcesContent":["@import\"https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,500;0,600;0,700;1,200;1,400;1,500;1,600;1,700&display=swap\";.split{display:flex;justify-content:space-between;align-items:center}.joined{border-left:8px solid #63d37d}.tick{color:#63d37d}.flex{display:flex;gap:6px;align-items:center}",null],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"split": `zH9ROxgcKWTuti5eIt5P`,
	"joined": `JXH_wQMqxkfFAAEu3DIg`,
	"tick": `eHzGgICKUJW9cxa5M9zw`,
	"flex": `z5tp54ZbQXC7IG6OfuPS`
};
export default ___CSS_LOADER_EXPORT___;
