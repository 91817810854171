import { Configuration } from "Context/Configuration";
import { HttpStatusCode } from "aphrodite.react/Helpers";
import { SwaggerResponse } from "./ClientApi";

export class ApiBase {
    protected transformOptions = async (options: RequestInit): Promise<RequestInit> => {
        options.credentials = "include";
        return Promise.resolve(options);
    };

    protected getBaseUrl = (_: string, _baseUrl?: string): string => {
        return Configuration.get().apiUri!;
    }

    protected transformResult(_url: string, response: Response, processor: (response: Response) => any) {
        if (response.status === HttpStatusCode.Http401Unauthorized) {
            localStorage.removeItem("token");
            location.href = `/login`;
            return;
        }

        if (response.status >= 400 && response.status < 600) {
            return new SwaggerResponse<null>(response.status, response.headers, null);
        }

        return processor(response);
    }
}