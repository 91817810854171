import { FC, useState } from "react";
import { useApp } from "Context/AppContext";
import { Button, CenterCover, CoverType, Form, Input } from "aphrodite.react/Components";
import { useValidator } from "aphrodite.react/Hooks";
import { HttpStatusCode } from "aphrodite.react/Helpers";
import styles from "./Login.module.scss";
import { SessionClient } from "ClientApi/ClientApi";

export const Login: FC = () => {
    const { resource } = useApp();
    const validator = useValidator();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleLoginClicked = async () => {
        if (!validator.canSubmit()) return;

        const session = new SessionClient();
        const response = await session.post(
            {
                email,
                password
            }
        );

        if (response.status === HttpStatusCode.Http200Ok) {
            location.href = "/app";
        }
    };

    return (
        <CenterCover coverType={CoverType.Relative}>
            <div className={styles.panel}>
                <h1>{resource("Login")}</h1>

                <Form>

                    <Input
                        type="email"
                        id="email"
                        label={resource("Email")}
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        validator={validator}
                        validations={{ required: true }}
                    />

                    <Input
                        type="password"
                        id="password"
                        label={resource("Password")}
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        validator={validator}
                        validations={{ required: true }}
                    />

                    <Button onClick={() => handleLoginClicked()}>
                        {resource("Login")}
                    </Button>

                </Form>
            </div>
        </CenterCover>
    );
};
