import { FC } from "react";
import { useApp } from "Context/AppContext";
import { useCustomer } from "./CustomerContext";
import { Button, Card } from "aphrodite.react/Components";
import { DateFormatter, HttpStatusCode, Severity } from "aphrodite.react/Helpers";
import { EventSignUpClient } from "ClientApi/ClientApi";
import { classNames } from "aphrodite.react/Utilities";
import styles from "./Customer.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export const Customer: FC = () => {
    const { resource } = useApp();
    const { events, refreshEvents } = useCustomer();

    const handleEventSignUpClicked = async (eventId: string) => {
        const response = await new EventSignUpClient().post(eventId);

        if (response.status === HttpStatusCode.Http204NoContent) {
            refreshEvents();
        }
    };

    const handleEventLeaveClicked = async (eventId: string) => {
        const response = await new EventSignUpClient().delete(eventId);

        if (response.status === HttpStatusCode.Http204NoContent) {
            refreshEvents();
        }
    };

    return (
        <>
            <h1>{resource("UpcomingEvents")}</h1>

            {!!events?.length && (
                <div>
                    {events.map(e => (
                        <Card key={e.id} className={classNames([e.joined && styles.joined])}>
                            <div className={styles.split}>
                                <h2>{DateFormatter.localeDateLocal(e.start)} {DateFormatter.localeTimeLocal(e.start)} - {e.name}</h2>
                                <span>
                                    {!e.joined && (
                                        <Button severity={Severity.Success} onClick={() => handleEventSignUpClicked(e.id)}>{resource("Join")}</Button>
                                    )}
                                    {e.joined && (
                                        <div className={styles.flex}>
                                            <span>
                                                <FontAwesomeIcon icon={faCheck} className={styles.tick} /> {resource("Joined")}
                                            </span>
                                            <Button severity={Severity.Danger} onClick={() => handleEventLeaveClicked(e.id)}>{resource("Leave")}</Button>
                                        </div>
                                    )}
                                </span>
                            </div>
                        </Card>
                    ))}
                </div>
            )}
        </>
    );
};