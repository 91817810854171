import { FC, useEffect, useState } from "react";
import { ConfigurationClient } from "ConfigurationApi/ConfigurationApi";
import { AppContext } from "Context/AppContext";
import { ResourceClient, SessionGetResponse } from "./ClientApi/ClientApi";
import { Layout } from "./Layout";
import { Routes, Route } from "react-router-dom";
import { Home } from "./Pages/Home";
import { Login } from "./Pages/Login/Login";
import { Dictionary, HttpStatusCode } from "aphrodite.react/Helpers";
import { CustomerArea } from "./Pages/Customer/CustomerArea";
import { Register } from "./Pages/Register/Register";
import { Language } from "./Helpers/Language";

export const App: FC = () => {
    const [resources, setResources] = useState<Dictionary<string> | null>(null);
    const [session, setSession] = useState<SessionGetResponse | null>(null);

    const getResources = async () => {
        const languageId = await Language.get();
        const client = new ResourceClient();
        var response = await client.get(languageId);
        if (response.status === HttpStatusCode.Http200Ok) {
            setResources(response.result);
        }
    };

    const resource = (key: string, variables?: Dictionary<string | number>) => {
        if (!!resources) {
            const result = resources[key];

            if (!!result) {
                let value = result;
                if (!!variables) {
                    Object.keys(variables).forEach(k => {
                        value = value.split(`{${k}}`).join(variables[k].toString());
                    });
                }

                return value;
            }
        }

        return `{{${key}}}`;
    };

    useEffect(() => {
        getResources();
    }, []);

    if (!resources) return null;

    return (
        <AppContext.Provider value={{ resource, session, setSession }}>
            <Layout>
                <Routes>
                    <Route index element={<Home />} />
                    <Route path="login/*" element={<Login />} />
                    <Route path="register/*" element={<Register />} />
                    <Route path="app/*" element={<CustomerArea />} />
                </Routes>
            </Layout>
        </AppContext.Provider>
    );
};