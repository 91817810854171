import { FC, PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import { useApp } from "Context/AppContext";
import styles from "./Layout.module.scss";
import { Button } from "aphrodite.react/Components";
import { HttpStatusCode, Severity } from "aphrodite.react/Helpers";
import { SessionClient } from "./ClientApi/ClientApi";
import { faDoorOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Layout: FC<PropsWithChildren> = (props) => {
    const { resource, session, setSession } = useApp();
    const navigate = useNavigate();

    const handleSignOut = async () => {
        const response = await new SessionClient().delete();

        if (response.status === HttpStatusCode.Http204NoContent) {
            setSession(null);
            navigate("/");
        }
    };

    return (
        <>
            <div className={styles.topBar}>
                <div className={styles.left}>
                    <span className={styles.text} onClick={() => navigate("/")}>
                        {resource("ApplicationName")}
                    </span>
                </div>

                <div className={styles.right}>
                    {!!session && (
                        <>
                            <span>{session.firstName} {session.lastName}</span>
                            <span>
                                <Button
                                    severity={Severity.Danger}
                                    onClick={() => handleSignOut()}
                                    title={resource("SignOut")}
                                >
                                    <FontAwesomeIcon icon={faDoorOpen} />
                                </Button>
                            </span>
                        </>
                    )}
                    {!session && (
                        <div className={styles.buttons}>
                            <Button
                                severity={Severity.Info}
                                onClick={() => navigate("/login")}
                            >
                                {resource("Login")}
                            </Button>
                            <Button
                                severity={Severity.Success}
                                onClick={() => navigate("/register")}
                            >
                                {resource("Register")}
                            </Button>
                        </div>
                    ) }
                </div>
            </div>

            <div className={styles.content}>
                {props.children}
            </div>
        </>
    );
};