import * as React from "react";
import { createRoot } from "react-dom/client";
import { App } from "./App";
import { BrowserRouter } from "react-router-dom";
import { Configuration } from "Context/Configuration";
import "./Styles/global.scss";

Configuration.refresh()
    .then(() => {
        const container = document.getElementById("root")!;
        const root = createRoot(container);
        root.render(
            <BrowserRouter>
                <App />
            </BrowserRouter>
        );
    });