import { FC, useEffect, useState } from "react";
import { useApp } from "Context/AppContext";
import { useCustomer } from "../CustomerContext";
import { Button, Form, TextArea } from "aphrodite.react/Components";
import { useValidator } from "aphrodite.react/Hooks";
import { ProfileClient } from "ClientApi/ClientApi";
import { HttpStatusCode } from "aphrodite.react/Helpers";
import { useNavigate } from "react-router-dom";

interface EditProfileProps {
    newAccount?: boolean;
}

export const EditProfile: FC<EditProfileProps> = (props) => {
    const { resource } = useApp();
    const validator = useValidator();
    const navigate = useNavigate();
    const { refreshProfile } = useCustomer();

    const [loaded, setLoaded] = useState<boolean>(false);

    const [bio, setBio] = useState<string>("");

    const getProfile = async () => {
        var response = await new ProfileClient().get();
        if (response.status === HttpStatusCode.Http200Ok) {
            setBio(response.result.bio ?? "");
        }
        setLoaded(true);
    };

    const handleSaveClicked = async () => {
        if (!validator.canSubmit()) return;

        var response = await new ProfileClient().put({
            bio: bio
        });

        if (response.status === HttpStatusCode.Http204NoContent) {
            refreshProfile();
            navigate("/app");
        }
    };

    useEffect(() => {
        getProfile();
    }, []);

    if (!loaded) return null;

    return (
        <>
            {!!props.newAccount && (
                <>
                    <h1>{resource("CreateProfile")}</h1>
                </>
            )}
            {!props.newAccount && (
                <>
                    <h1>{resource("EditProfile")}</h1>
                </>
            )}

            <Form>
                <TextArea
                    id="bio"
                    label={resource("Bio")}
                    value={bio}
                    onChange={(e) => setBio(e.target.value)}
                    grow={true}
                    validations={{ required: true }}
                    validator={validator}
                />

                <Button
                    onClick={() => handleSaveClicked()}
                >
                    {resource("Save")}
                </Button>
            </Form>
        </>
    );
};