import { Button, CenterCover, CoverType, Form, Input } from "aphrodite.react/Components";
import { useValidator } from "aphrodite.react/Hooks";
import { FC, useState } from "react";
import { useApp } from "Context/AppContext";
import { useNavigate } from "react-router-dom";
import { AccountClient, AccountPostRequest, EmailVerificationClient, EmailVerificationPostRequest, EmailVerificationResult, SessionClient } from "ClientApi/ClientApi";
import { HttpStatusCode } from "aphrodite.react/Helpers";
import styles from "./Register.module.scss";
import { Language } from "../../Helpers/Language";

enum RegisterPhase {
    Email, Details, AlreadyRegistered
}

export const Register: FC = () => {
    const { resource } = useApp();
    const navigate = useNavigate();
    const validator = useValidator();

    const [phase, setPhase] = useState<RegisterPhase>(RegisterPhase.Email);
    const [busy, setBusy] = useState<boolean>(false);

    const [email, setEmail] = useState<string>("");
    const [emailVerificationCode, setEmailVerificationCode] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");

    const handleEmailPhaseNextClicked = async () => {
        if (!validator.canSubmit()) return;

        setBusy(true);

        const request: EmailVerificationPostRequest = {
            email
        };

        const response = await new EmailVerificationClient().post(request);

        if (response.status === HttpStatusCode.Http200Ok) {
            switch (response.result.result) {
                case EmailVerificationResult.AlreadyRegistered:
                    validator.reset();
                    setPhase(RegisterPhase.AlreadyRegistered);
                    break;
                case EmailVerificationResult.VerificationCodeSent:
                    validator.reset();
                    setPhase(RegisterPhase.Details);
                    break;
            }

            setBusy(false);
        }
    };

    const handleDetailsPhaseNextClicked = async () => {
        if (!validator.canSubmit()) return;

        setBusy(true);

        const request: AccountPostRequest = {
            firstName,
            lastName,
            email,
            password,
            emailVerificationCode,
            countryId: 1,
            languageId: await Language.get()
        };

        const response = await new AccountClient().post(request);

        if (response.status === HttpStatusCode.Http200Ok) {
            await new SessionClient().post({
                email,
                password
            });

            if (response.status === HttpStatusCode.Http200Ok) {
                navigate("/app", { replace: true });
            }
        }
    };

    return (
        <CenterCover coverType={CoverType.Relative}>
            <div className={styles.panel}>

                <h1>Register</h1>

                {phase === RegisterPhase.Email && (
                    <Form>
                        <Input
                            id="email"
                            validator={validator}
                            validations={{ required: true }}
                            label={resource("Email")}
                            type="email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />

                        <Button
                            disabled={busy}
                            onClick={() => handleEmailPhaseNextClicked()}
                        >
                            {resource("Next")}
                        </Button>
                    </Form>
                )}

                {phase === RegisterPhase.Details && (
                    <Form>
                        <Input
                            id="firstName"
                            validator={validator}
                            validations={{ required: true }}
                            label={resource("FirstName")}
                            value={firstName}
                            onChange={e => setFirstName(e.target.value)}
                        />

                        <Input
                            id="lastName"
                            validator={validator}
                            validations={{ required: true }}
                            label={resource("LastName")}
                            value={lastName}
                            onChange={e => setLastName(e.target.value)}
                        />

                        <Input
                            id="password"
                            validator={validator}
                            validations={{ required: true }}
                            label={resource("Password")}
                            type="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />

                        <Input
                            id="confirmPassword"
                            validator={validator}
                            validations={{ required: true }}
                            label={resource("ConfirmPassword")}
                            type="password"
                            value={confirmPassword}
                            onChange={e => setConfirmPassword(e.target.value)}
                        />

                        <Input
                            id="verificationCode"
                            validator={validator}
                            validations={{ required: true, minLength: 6, maxLength: 6, numeric: true }}
                            label={resource("EmailVerificationCode")}
                            value={emailVerificationCode}
                            onChange={e => setEmailVerificationCode(e.target.value)}
                        />

                        <Button
                            disabled={busy}
                            onClick={() => handleDetailsPhaseNextClicked()}
                        >
                            {resource("Register")}
                        </Button>
                    </Form>
                )}

                {phase === RegisterPhase.AlreadyRegistered && (
                    <>
                        <p>{resource("EmailAlreadyRegistered")}</p>

                        <Button
                            onClick={() => navigate("/login", { replace: true })}
                        >
                            {resource("Login")}
                        </Button>
                    </>
                )}

            </div>
        </CenterCover>
    );
};