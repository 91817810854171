import { FC, useEffect, useState } from "react";
import { EventClient, EventGetAllResponse, ProfileClient, ProfileGetResponse, SessionClient } from "ClientApi/ClientApi";
import { useApp } from "Context/AppContext";
import { HttpStatusCode } from "aphrodite.react/Helpers";
import { Route, Routes } from "react-router-dom";
import { CustomerContext } from "./CustomerContext";
import { EditProfile } from "./Profile/EditProfile";
import { Customer } from "./Customer";

export const CustomerArea: FC = () => {
    const { session, setSession } = useApp();
    const [profile, setProfile] = useState<ProfileGetResponse | null | undefined>(null);
    const [events, setEvents] = useState<EventGetAllResponse[] | null>(null);

    const refreshSession = async () => {
        const response = await new SessionClient().get();

        if (response.status === HttpStatusCode.Http200Ok) {
            setSession(response.result);
        }
    };

    const refreshProfile = async () => {
        const response = await new ProfileClient().get();

        switch (response.status) {
            case HttpStatusCode.Http200Ok:
                setProfile(response.result);
                break;
            case HttpStatusCode.Http404NotFound:
                setProfile(undefined);
                break;
        }
    };

    const refreshEvents = async () => {
        const response = await new EventClient().getAll();

        if (response.status === HttpStatusCode.Http200Ok) {
            setEvents(response.result);
        }
    };

    useEffect(() => {
        refreshSession();
        refreshProfile();
        refreshEvents();
    }, []);

    if (!session || profile === null) return null;

    return (
        <CustomerContext.Provider value={{ profile, refreshProfile, events, refreshEvents }}>
            {!profile && (
                <EditProfile newAccount={true} />
            )}

            {!!profile && (
                <Routes>
                    <Route index element={<Customer />} />
                </Routes>
            )}
        </CustomerContext.Provider>
    );
};