import { createContext, useContext } from "react";
import { SessionGetResponse } from "ClientApi/ClientApi";
import { Dictionary } from "aphrodite.react/Helpers";

interface AppContextState {
    resource: (key: string, variables?: Dictionary<string | number>) => string;
    session: SessionGetResponse | null;
    setSession: (session: SessionGetResponse | null) => void;
}

export const AppContext = createContext<AppContextState>({
    resource: () => {
        return "No resource finder has been implemented";
    },
    session: null,
    setSession: () => { }
});

export function useApp() {
    return useContext(AppContext);
};
