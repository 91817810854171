// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,500;0,600;0,700;1,200;1,400;1,500;1,600;1,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{box-sizing:border-box}body{position:absolute;top:0;left:0;right:0;bottom:0;overflow-y:auto;font-family:"Poppins","Segoe UI",Tahoma,Geneva,Verdana,sans-serif;margin:0;padding:0;background-color:#fee}`, "",{"version":3,"sources":["webpack://./Client/Styles/global.scss","webpack://./../Aphrodite.React/Styles/defaults.scss"],"names":[],"mappings":"AAEA,EACI,qBAAA,CAGJ,KACI,iBAAA,CACA,KAAA,CACA,MAAA,CACA,OAAA,CACA,QAAA,CACA,eAAA,CACA,iECVU,CDWV,QAAA,CACA,SAAA,CACA,qBCFS","sourcesContent":["@import\"https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,500;0,600;0,700;1,200;1,400;1,500;1,600;1,700&display=swap\";*{box-sizing:border-box}body{position:absolute;top:0;left:0;right:0;bottom:0;overflow-y:auto;font-family:\"Poppins\",\"Segoe UI\",Tahoma,Geneva,Verdana,sans-serif;margin:0;padding:0;background-color:#fee}",null],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
