import { createContext, useContext } from "react";
import { EventGetAllResponse, ProfileGetResponse } from "ClientApi/ClientApi";

interface CustomerContextState {
    profile: ProfileGetResponse | null | undefined;
    refreshProfile: () => void;
    events: EventGetAllResponse[] | null;
    refreshEvents: () => void;
}

export const CustomerContext = createContext<CustomerContextState>({
    profile: null,
    refreshProfile: () => { },
    events: null,
    refreshEvents: () => { }
});

export function useCustomer() {
    return useContext(CustomerContext);
}